// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/m";globalThis["SENTRY_RELEASE"] = {"id":"F1u6rSSf21b-FN_mtgCwc"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
  environment: process.env.DEPLOYMENT,
  dsn: 'https://e4d79b77e127e02255ee7dc52085d467@o1184201.ingest.us.sentry.io/4506516554907648',
  denyUrls: [/localhost/, /127.0.0.1/, /c\.realisticgroup\.com/],
  enabled: process.env.NODE_ENV !== 'development' && !process.env.CI,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  profilesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: process.env.DEPLOYMENY === 'stable' ? 1.0 : 0.0,
  tracesSampleRate: 1.0,
  skipOpenTelemetrySetup: true,
  transportOptions: {
    fetchOptions: {
      priority: 'low',
    },
  },
  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
})
